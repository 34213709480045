var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 12 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'pid',
              { rules: [{ required: true, message: '请选择！' }] },
            ]),expression:"[\n              'pid',\n              { rules: [{ required: true, message: '请选择！' }] },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true}},_vm._l((_vm.categoryList),function(item){return _c('a-select-option',{key:item.pid,attrs:{"value":item.pid}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),(_vm.pid === '17')?_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"专业类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['major',
              { rules: [{ required: true, message: '请选择！' }] },
            ]),expression:"['major',\n              { rules: [{ required: true, message: '请选择！' }] },\n            ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.docMajorType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1):_vm._e(),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"资料名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              { rules: [{ required: true, message: '请输入！' }] },
            ]),expression:"[\n              'name',\n              { rules: [{ required: true, message: '请输入！' }] },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":12}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('a-space',[_c('Upload',{attrs:{"list":_vm.attachmentList},on:{"change":_vm.change}}),(_vm.attachmentList.length > 0)?_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove.apply(null, arguments)}}},[_vm._v("删除")]):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.loading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }