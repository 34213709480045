<template>
    <div>
        <input ref="upload" style="display: none" type="file" @change="onChange" />
        <a-button :loading="loading" @click="onClick">
            {{ list.length > 0 ? list[0].name : '点击上传' }}
        </a-button>
    </div>
</template>
  
<script>
import { uploadFile } from "@/api/upload";
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        onClick() {
            this.$refs.upload.click();
        },

        onChange(e) {
            const files = e.target.files;
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append("files", files[i]);
            }

            this.loading = true;

            uploadFile(data)
                .then((res) => {
                    console.log("upload res", res);
                    if (Array.isArray(res)) {
                        this.$emit("change", res);
                    }
                })
                .finally(() => {
                    this.loading = false;
                    e.target.value = null;
                });
        },

    },
};
</script>